<template>
  <transition name="slide-up">
    <card
      :header-classes="{ 'has-background-dark': true }"
      :body-classes="{
        'is-center': true,
        'is-paddingless': fl.type === 'date'
      }"
      class="add-filter-card"
    >
      <template slot="header">
        <p class="card-header-title has-text-white">
          {{ fl.label }}
        </p>

        <a class="card-header-icon has-text-white" @click="close">
          <b-icon icon="times" size="is-small" />
        </a>
      </template>

      <template slot="body">
        <div class="content">
          <b-field
            :type="showError ? 'is-danger' : ''"
            :message="showError ? errorMessage : ''"
          >
            <b-input
              v-if="
                fl.type === 'string' ||
                  fl.type === 'number' ||
                  fl.type === 'email'
              "
              v-model="fl.value"
              v-focus
              :placeholder="fl.label"
              :type="fl.type"
              @keyup.native.enter="applyFilter"
            />

            <div v-if="fl.type === 'date'">
              <b-field class="has-padding-top-75 has-content-justified-center">
                <b-radio-button v-model="fl.operator" native-value="before">
                  Before
                </b-radio-button>

                <b-radio-button v-model="fl.operator" native-value="equals">
                  Equals
                </b-radio-button>

                <b-radio-button v-model="fl.operator" native-value="after">
                  After
                </b-radio-button>
              </b-field>

              <b-datepicker v-model="fl.value" inline @input="applyFilter" />
            </div>

            <div v-if="fl.type === 'select-checkbox'">
              <div
                v-for="(option, index) in fl.options"
                :key="index"
                class="field has-margin-left-0"
              >
                <b-checkbox v-model="fl.value" :native-value="option.value">
                  {{ option.label }}
                </b-checkbox>
              </div>
            </div>

            <div v-if="fl.type === 'select-radio'">
              <div
                v-for="(option, index) in fl.options"
                :key="index"
                class="field has-margin-left-0"
              >
                <b-radio v-model="fl.value" :native-value="option.value">
                  {{ option.label }}
                </b-radio>
              </div>
            </div>

            <b-select v-if="fl.type === 'select'" v-model="fl.value" expanded>
              <option
                v-for="option in fl.options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </b-select>
          </b-field>
        </div>
      </template>

      <template v-if="fl.type !== 'date'" slot="footer">
        <a role="button" class="card-footer-item" @click="applyFilter">Apply</a>
      </template>
    </card>
  </transition>
</template>

<script>
export default {
  props: {
    filter: {
      default() {
        return {};
      },
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fl: this.getFl(),
      errorMessage: "Please enter valid value",
      isSubmitted: false
    };
  },
  computed: {
    isValid() {
      let isValid = this.fl.value.toString().length > 0;

      if (!isValid) {
        return isValid;
      }

      if (!this.$_.has(this.fl, "validators")) {
        return isValid;
      }

      this.fl.validators.forEach(validator => {
        isValid = this.$_.isFunction(validator.method)
          ? validator.method(this.fl.value)
          : this[`${validator.method}Validator`]();

        if (!isValid) {
          if (this.$_.has(validator, "message")) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.errorMessage = validator.message;
          }
          return false;
        }
      });

      return isValid;
    },

    showError() {
      return this.isSubmitted && !this.isValid;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    applyFilter() {
      this.isSubmitted = true;

      if (this.isValid) {
        this.$emit("applyFilter", this.fl);
      }
    },
    setInitialValue(filter) {
      if (this.$_.has(filter, "value")) {
        return filter;
      }

      if (filter.type === "select-checkbox") {
        filter.value = [];
        return filter;
      }

      if (filter.type === "date") {
        filter.value = new Date();
        return filter;
      }

      filter.value = "";

      return filter;
    },
    setOperator(filter) {
      if (!this.$_.has(filter, "operator")) {
        filter.operator = "equals";
      }
      return filter;
    },
    getFl() {
      return this.setInitialValue(this.setOperator(this.$_.clone(this.filter)));
    },

    //validators
    emailValidator() {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.fl.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.add-filter-card {
  z-index: 10;
  min-width: 300px;
  max-width: 400px;
  position: absolute;
  top: -5rem;
  box-shadow: 0 0 0 200vh rgba($black, 0.25);
}
.new-filter.slide-up-leave-active {
  transition: none;
}
.new-filter.slide-up-leave-to {
  transform: none;
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 300ms ease;
}
.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
